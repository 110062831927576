const sessionStorageSupported =
    typeof window['sessionStorage'] != 'undefined' && window['sessionStorage'] != null;

export class SessionStorageUtils {
    static set(key: string, item: string) {
        if (sessionStorageSupported) sessionStorage.setItem(key, item);
    }

    static get(key: string): string {
        return sessionStorageSupported ? sessionStorage.getItem(key) : null;
    }

    static remove(key: string) {
        if (sessionStorageSupported) sessionStorage.removeItem(key);
    }

    static clear() {
        if (sessionStorageSupported) sessionStorage.clear();
    }
}
