import { Container, Service } from 'typedi';
import { ClientLogger } from '../../injection-tokens';
import { LoggerConfigurationProvider } from './implementations';
import { LoggerFactory } from './implementations/logger-factory';
import { ILogger, ILoggerConfigurationProvider } from './interfaces';

@Service()
export class LoggerProvider {
    private readonly _clientLogger: Console;
    private readonly _loggerConfigurationProvider: ILoggerConfigurationProvider;

    constructor() {
        this._clientLogger = Container.get(ClientLogger);
        this._loggerConfigurationProvider = Container.get(LoggerConfigurationProvider);
    }

    getLogger(className: string): ILogger {
        return LoggerFactory.create(
            className,
            this._clientLogger,
            this._loggerConfigurationProvider,
        );
    }
}
