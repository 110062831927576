export enum LogLevel {
    Debug,
    Info,
    Warning,
    Error,
    None,
}

export const MinLevelToLog = {
    [LogLevel.Debug]: 1,
    [LogLevel.Info]: 2,
    [LogLevel.Warning]: 3,
    [LogLevel.Error]: 4,
    [LogLevel.None]: 5,
};

export const LogLevelMap: Partial<Record<keyof Console, LogLevel>> | Record<'none', LogLevel> = {
    debug: LogLevel.Debug,
    log: LogLevel.Info,
    info: LogLevel.Info,
    table: LogLevel.Info,
    warn: LogLevel.Warning,
    error: LogLevel.Error,
    none: LogLevel.None,
};
