import DeferredObject from '../Modules/Utils/DeferredObject';
import { Token } from 'typedi';
import { IFeatureAvailability } from './modules/feature/feature-availability/feature-availability-interface';
import { IUserContext } from './modules/user-context/user-context-interface';
import { IClientInit } from './modules/client-integration/init/interfaces/IClientInit';
import { IClientIntegrationFacade } from './modules/client-integration/interfaces/IClientIntegrationFacade';
import { ILibraryManager } from './modules/external/library/ILibraryManager';
import { ClientSettings } from './modules/client-integration/settings/client-settings';
import { ISessionUserData } from './modules/session-manager/interfaces/ISessionUserData';

export const WindowToken = new Token<Window>('window');
export const SessionStorageToken = new Token<Storage>('sessionStorage');
export const LocalStorageToken = new Token<Storage>('localStorage');
export const ClientLogger = new Token<Console>('clientLogger');
export const SessionTimerInterval = new Token<number>('sessionTimerInterval');
export const UCFData = new Token<any>('unifiedClientDataProvider');
export const PerformanceToken = new Token<any>('performance');
export const GTMDataLayerToken = new Token<any>('GTMDataLayer');
export const PreloaderToken = new Token<any>('preloader');
export const ClientIntegrationFacadeToken = new Token<IClientIntegrationFacade>(
    'clientIntegrationFacade',
);
export const ClientInitToken = new Token<IClientInit>('clientInitToken');
export const ClientSettingsToken = new Token<ClientSettings>('clientSettingsToken');
export const UserDataStoreDeferredObjectToken = new Token<DeferredObject<ISessionUserData>>(
    'userDataStoreDeferredObjectToken',
);
export const DataStoreDeferredObjectToken = new Token<DeferredObject<void>>(
    'dataStoreDeferredObjectToken',
);
export const FeatureAvailabilityToken = new Token<IFeatureAvailability>('featureAvailability');
export const UserContextToken = new Token<IUserContext>('UserContext');

export const LibraryManagerToken = new Token<ILibraryManager>('libraryManagerToken');
