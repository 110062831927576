import { Service } from 'typedi';

import { LocalStorageUtils } from '../../../../Modules/Storage/utils';
import { LogLevelMap, MinLevelToLog } from '../enums';
import { ILoggerConfigurationProvider } from '../interfaces';
import { ILoggerConfiguration } from '../models';

//TO DO - use localStorageService
@Service()
export class LoggerConfigurationProvider implements ILoggerConfigurationProvider {
    constructor() {}

    get = (): ILoggerConfiguration => {
        const { defaultLogLevel = 'error' } = pageContextData.environment;
        const _defaultLogLevel = MinLevelToLog[LogLevelMap[defaultLogLevel]];
        return {
            MinLogLevel: Number(LocalStorageUtils.get('UC.MINLOGLEVEL') || _defaultLogLevel),
        };
    };
}
