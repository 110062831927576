import { LogLevel, LogLevelMap } from '../enums';
import { ILogger, ILoggerConfigurationProvider } from '../interfaces';

export class LoggerFactory {
    public static create(
        className: string,
        clientLogger: Console,
        configProvider: ILoggerConfigurationProvider,
    ): ILogger {
        function formatMessage(logLevel: LogLevel, ...args) {
            return `UCF: ${className} : ${LogLevel[logLevel]} : ${args}`;
        }

        return new Proxy<ILogger>(clientLogger as any, {
            get(_, property) {
                return (text: string, ...args) => {
                    const { defaultLogLevel = 'info' } = pageContextData.environment;
                    const logLevel = LogLevelMap[property ?? defaultLogLevel];
                    const message = formatMessage(logLevel, text);
                    // Some special handling due to flawed ILogger interface
                    const funcName = property === 'warning' ? 'warn' : property;

                    if (property === 'table') {
                        clientLogger.log(message);
                        clientLogger.table(...(args || []));
                        return;
                    }

                    clientLogger[funcName]?.(message, ...(args || []));
                };
            },
        });
    }
}
