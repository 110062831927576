import pageContextData, {
    GlobalizationData,
    ClientProviderData,
    FeaturesData,
    InfraData,
    SmartBannerData,
    TouchIdData,
    UrlResourceData,
    VisualData,
    ReleaseData,
    SiteData,
    AirshipData,
    BrandData,
    DeviceData,
    EnvironmentData,
    GeoComplyData,
    SurveyData,
    ThemeData,
    NativeData,
    PageContextData,
    MigrationData,
    OptimizelyData,
} from 'page-context-data';

import {
    AuthenticatedPageContextData,
    AuthenticatedSessionData,
    AuthenticatedUserData,
    CombinedLocalizationData,
    CombinedNetworkData,
    CombinedPageContextData,
    CombinedRegulationData,
} from 'page-context-manager';

import { LogLevelMap, MinLevelToLog } from '../../logger/enums';
import { LoggerFactory, ILogger } from '../../logger';
import { ILoggerConfigurationProvider } from '../../logger/interfaces';
import { ILoggerConfiguration } from '../../logger/models';
import { IPageContextManager } from './models/IPageContextManager';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

class PageContextManager implements IPageContextManager {
    private _authenticatedPageContextData: AuthenticatedPageContextData = {};
    private _combinedPageContextData: CombinedPageContextData =
        pageContextData as CombinedPageContextData;
    private _logger: ILogger;

    constructor(logger: ILogger) {
        this._logger = logger;
    }

    public updatePageContext = (updates: Partial<PageContextData>): void => {
        this._logger.info(
            `updatePageContext: updating page context data with the following information: `,
            updates,
        );

        merge(pageContextData, pageContextData, updates);
        this._combinedPageContextData = merge(
            {},
            pageContextData,
            this._authenticatedPageContextData,
        );
    };

    public updatePageContextAuthenticated = (
        updates: Partial<AuthenticatedPageContextData>,
    ): void => {
        this._logger.info(
            `updatePageContextAuthenticated: updating page context data with the following user information: `,
            updates,
        );
        merge(this._authenticatedPageContextData, updates);
        this._combinedPageContextData = merge(
            {},
            pageContextData,
            this._authenticatedPageContextData,
        );
    };

    public removePageContext = (): void => {
        this._logger.info('removePageContext: removing page context data');

        Object.keys({ ...pageContextData }).forEach((key) => {
            delete pageContextData[key];
        });

        this._combinedPageContextData = merge(
            {},
            pageContextData,
            this._authenticatedPageContextData,
        );
    };

    public removePageContextAuthenticated = (): void => {
        this._logger.info(
            'removePageContextAuthenticated: removing authenticated page context data',
        );
        this._authenticatedPageContextData = {};
        this._combinedPageContextData = merge(
            {},
            pageContextData,
            this._authenticatedPageContextData,
        );
    };

    public getGlobalizationData = (): GlobalizationData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.globalization));

    public getRegulationData = (): CombinedRegulationData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.regulation));

    public getLocalizationData = (): CombinedLocalizationData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.localization));

    public getDeviceData = (): DeviceData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.device));

    public getAirshipData = (): AirshipData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.airship));

    public getGeoComplyData = (): GeoComplyData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.geoComply));

    public getFeaturesData = (): FeaturesData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.features));

    public getThemeData = (): ThemeData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.theme));

    public getSurveyData = (): SurveyData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.survey));

    public getEnvironmentData = (): EnvironmentData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.environment));

    public getBrandData = (): BrandData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.brand));

    public getReleaseData = (): ReleaseData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.release));

    public getSiteData = (): SiteData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.site));

    public getNativeData = (): NativeData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.native));

    public getTouchIdData = (): TouchIdData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.touchId));

    public getClientProviderData = (): ClientProviderData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.clientProvider));

    public getSmartBannerData = (): SmartBannerData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.smartBanner));

    public getOptimizelyData = (): OptimizelyData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.optimizely));

    public getUrlResourceData = (): UrlResourceData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.urlResource));

    public getInfraData = (): InfraData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.infra));

    public getVisualData = (): VisualData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.visual));

    public getNetworkData = (): CombinedNetworkData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.network));

    public getUserData = (): AuthenticatedUserData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.user));

    public getSessionData = (): AuthenticatedSessionData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.session));

    public getMigrationData = (): MigrationData =>
        cloneDeep(this._withDefault(this._combinedPageContextData.migration));

    private _withDefault = <T>(value: T, defaultValue: T = {} as T): T => {
        return value ?? defaultValue;
    };
}

class LoggerConfigurationProvider implements ILoggerConfigurationProvider {
    get = (): ILoggerConfiguration => {
        const { defaultLogLevel = 'error' } = pageContextData.environment;
        const _defaultLogLevel = MinLevelToLog[LogLevelMap[defaultLogLevel]];
        return {
            MinLogLevel: Number(localStorage.getItem('UC.MINLOGLEVEL') || _defaultLogLevel),
        };
    };
}

const logger = LoggerFactory.create(
    'PageContextManager',
    window.console,
    new LoggerConfigurationProvider(),
);

const Instance: IPageContextManager = new PageContextManager(logger);

export const {
    getGlobalizationData,
    getRegulationData,
    getLocalizationData,
    getDeviceData,
    getAirshipData,
    getGeoComplyData,
    getFeaturesData,
    getThemeData,
    getSurveyData,
    getEnvironmentData,
    getBrandData,
    getNativeData,
    getSiteData,
    getTouchIdData,
    getClientProviderData,
    getSmartBannerData,
    getOptimizelyData,
    getUrlResourceData,
    getReleaseData,
    getInfraData,
    getVisualData,
    getNetworkData,
    getUserData,
    getSessionData,
    updatePageContext,
    updatePageContextAuthenticated,
    removePageContextAuthenticated,
    removePageContext,
} = Instance;

export default Instance;
