const localStorageSupported =
    typeof window['localStorage'] != 'undefined' && window['localStorage'] != null;

export class LocalStorageUtils {
    static set(key: string, item: string) {
        if (localStorageSupported) localStorage.setItem(key, item);
    }

    static get(key: string): string {
        return localStorageSupported ? localStorage.getItem(key) : null;
    }

    static remove(key: string) {
        if (localStorageSupported) localStorage.removeItem(key);
    }

    static clear() {
        if (localStorageSupported) localStorage.clear();
    }
}
