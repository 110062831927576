import { Container } from 'typedi';
import { WindowToken } from '../../../injection-tokens';
import { LogLevelMap, MinLevelToLog } from '../enums';
import { LoggerConfigurationProvider } from './loggerConfigurationProvider';

export const TrackJSClientLogger = new Proxy<Console>(console, {
    get(console, property: keyof Console) {
        const configProvider = Container.get(LoggerConfigurationProvider);
        const minLogLevel = configProvider.get().MinLogLevel;
        const logLevel = LogLevelMap[property];

        return (...args) => {
            const logger =
                MinLevelToLog[logLevel] >= minLogLevel
                    ? console
                    : (Container.get(WindowToken) as any)?.TrackJS?.console;
            logger?.[property]?.apply(null, args || []);
        };
    },
});
